// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("/opt/build/repo/node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-app-js": () => import("/opt/build/repo/src/pages/chi-siamo-app.js" /* webpackChunkName: "component---src-pages-chi-siamo-app-js" */),
  "component---src-pages-chi-siamo-js": () => import("/opt/build/repo/src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contattaci-js": () => import("/opt/build/repo/src/pages/contattaci.js" /* webpackChunkName: "component---src-pages-contattaci-js" */),
  "component---src-pages-contatti-js": () => import("/opt/build/repo/src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-intermediari-js": () => import("/opt/build/repo/src/pages/intermediari.js" /* webpackChunkName: "component---src-pages-intermediari-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

